import { Routes, Route, useSearchParams, useLocation } from 'react-router-dom'

import CheckOptions from './components/CheckOptions';
import OpenbankingCallback from './components/OpenbankingCallback';
import OpenbankingStart from './components/OpenbankingStart';
import IdentityCheck from './components/IdentityCheck';
import Home from './components/Home';
import { useEffect, useState } from 'react';
import { initStore } from './store/Actions';
import OtpCallback from './components/OtpCallback';
import OtpCheck from './components/OtpCheck';
import NotFound from './components/NotFound';
import Loader from './components/common/Loader';
import { FadeLoader } from 'react-spinners';
import OtpMail from './components/OtpMail';
import BankingTransferStart from './components/BankingTransferStart';
import BankingTransferCallback from './components/BankingTransfertCallback';


const App = () => {

  const [isResourceLoaded, setIsResourceLoaded] = useState(false)
  const [params] = useSearchParams()

  const location = useLocation()

  useEffect(() => {
    initStore(params, () => setIsResourceLoaded(true))
  }, [params])

  return (
    <>
      {isResourceLoaded ?
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/CheckOptions" element={<CheckOptions />} />
          <Route path="/OpenbankingCallback" element={<OpenbankingCallback />} />
          <Route path='/OpenBankingStart' element={<OpenbankingStart />} />
          <Route path="/IdentityCheck" element={<IdentityCheck />} />
          <Route path="/OtpCheck" element={<OtpCheck />} />
          <Route path="/OtpCallback" element={<OtpCallback />} />
          <Route path="/BankingTransferStart" element={<BankingTransferStart />} />
          <Route path="/BankingTransferCallback" element={<BankingTransferCallback />} />
          <Route path="/*" element={<NotFound />} />
          { process.env.REACT_APP_ENV === 'Demoshop' &&
            <Route path="/OtpMail" element={<OtpMail />} />
          }
        </Routes> :
        <> {
          location.pathname === '/SinglePage' ?
            <div className='container az-center'>
              <Loader loading={true} />
            </div>
            :
            <div className="transparent container">
              <div className="row az-center">
                <FadeLoader color="#FFFFFF" />
              </div>
            </div>
        }
        </>
      }
    </>
  );
}

export default App;
