import {combineReducers} from 'redux';
import { configureStore } from "@reduxjs/toolkit";
import storage from 'redux-persist/lib/storage';

import {Reducer} from './Reducers';
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER
  } from "redux-persist";

const persistConfig = {
    key: "root",
    storage
}
 
const reducer = combineReducers({ Reducer });

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    }),
    devTools : process.env.NODE_ENV === "development"
  });

export const persistedStore = persistStore(store); 


 
