import { useEffect, useState } from "react";
import { isEmpty, sleep } from "../Util";
import { useNavigate } from "react-router-dom";
import {cancelTransaction, fetchResources, getQuote, verifyTransaction } from "../store/Actions";
import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import Layout from "./common/Layout";
import Error from "./common/Error";
import Back from "./common/Back";
import { FadeLoader } from "react-spinners";

const Home = () => { 

  const [isResourceLoaded, setIsResourceLoaded] = useState(false)
  const [isQuoteCreated, setIsQuoteCreated] = useState(false)
  const [requirement, setRequirement] = useState(undefined)
  const [callbackUrl, setCallbackUrl] = useState(undefined)
  const [quoteRefused, setQuoteRefused] = useState(false)
  const [tooManyAttempts, setTooManyAttempts] = useState(false)

  const navigate = useNavigate();

  const error = useSelector((state) => state.Reducer.error)

  useEffect(() => {      
    window.addEventListener("beforeunload", cancelTransaction);
    return () => window.removeEventListener("beforeunload", cancelTransaction);
  }, []);

  useEffect(() => {
      if (isResourceLoaded && isQuoteCreated && isEmpty(error)) {
        if (!isEmpty(callbackUrl)) {
          window.removeEventListener("beforeunload", cancelTransaction);
          window.location.replace(callbackUrl)
        } else
          switch(requirement) {
            case "Otp" : verifyTransaction(() => navigate("/OtpCheck")); break
            case "StrongVerification" : navigate("/CheckOptions"); break
          }
      }
  }, [isResourceLoaded, isQuoteCreated, requirement, callbackUrl, error]); 

  const handleGetQuote = async() => {
    let i = 0
    while (i < 60) {
      if (! isEmpty(document.ftrToken)) {
        getQuote(document.ftrToken, 
          (requirement, callbackUrl, quoteRefused, tooManyAttempts) => {   
            setRequirement(requirement)
            setCallbackUrl(callbackUrl)
            setQuoteRefused(quoteRefused)
            setTooManyAttempts(tooManyAttempts)
          }, 
          () => {
            setIsQuoteCreated(true)
            document.removeEventListener('ftr:tokenReady', handleGetQuote)
          })
        return;
      }
      const timeout = await sleep(1000)
      clearTimeout(timeout)
      i++
    }
    setQuoteRefused(true)
  }

  useEffect(() => {
    fetchResources(() => {setIsResourceLoaded(true)})
    handleGetQuote();    
  }, [])

  const { t } = useTranslation();
  return ( 
    <>
    { quoteRefused === false && isEmpty(error) ?
      <div className="transparent container">
        <div className="row az-center">
            <FadeLoader color="#FFFFFF"/>
        </div>
      </div> :
      <Layout isLarge={false}>  
        <Error renderer={quoteRefused} message={quoteRefused ? 
                tooManyAttempts ? t('home.refused.cap.text') : t('home.refused.text') : t('home.error.text')} />
        <Back renderer={!isEmpty(error)} message={t('app.back.link')} />
      </Layout>
    }
    </>
  );
}

export default Home;